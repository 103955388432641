import React from 'react'
import { Col, Row } from 'reactstrap'

interface Props {
  title: string
  description: JSX.Element
}

export const InformationRow: React.FC<Props> = ({
  title,
  description,
}): JSX.Element => {
  return (
    <Row>
      <Col md={12}>
        <h1 className="h4 text-primary font-weight-bold mb-1">{title}</h1>
        <p style={{ lineHeight: '22px' }}>{description}</p>
      </Col>
    </Row>
  )
}
