import { useMemo } from 'react'
import { useAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'

export const usePublicRemoteConfig = () => {
  const getPublicSystemAvailability = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/public-system-availability`,
    }),
    []
  )

  const getPublicSystemAvailabilityTask = useAxios<AxiosResponse<string>>(
    axios,
    getPublicSystemAvailability
  )

  return {
    result: getPublicSystemAvailabilityTask.result?.data,
    loaded:
      getPublicSystemAvailabilityTask.result ||
      getPublicSystemAvailabilityTask.error,
  }
}
