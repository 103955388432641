import { ManagedUser } from '../types'

const conv = (s?: string) => (s ? s.toLowerCase() : 'Z')

export const getSortedUsers = (users: ManagedUser[]) =>
  [...users].sort((a: ManagedUser, b: ManagedUser) => {
    const nameDiff = conv(a.name).localeCompare(conv(b.name))
    if (nameDiff !== 0) return nameDiff

    const surnameDiff = conv(a.surname).localeCompare(conv(b.surname))
    if (surnameDiff !== 0) return surnameDiff

    const emailDiff = conv(a.email).localeCompare(conv(b.email))
    if (emailDiff !== 0) return emailDiff

    return (a.userId || '').localeCompare(b.userId || '')
  })

export const getUsersByRole = (users: ManagedUser[], role: string) =>
  users.filter((it) => it.role === role)

export const validateEmail = (email: string) => {
  /* eslint-disable */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  /* eslint-enable */
  return re.test(email.toLowerCase())
}
