import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, FormFeedback, Label, Row, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga'
import { faExclamationTriangle, faPlus } from '@fortawesome/pro-solid-svg-icons'
import {
  AddUserEvents,
  calculateAddUserEvent,
  ManagedUser,
  ROLES,
  snakeCase,
  validateEmail,
} from '../../../common'
import { AsyncTask } from 'react-hooks-async'
import { AxiosError, AxiosResponse } from 'axios'
import { AddUserModal } from '../add-user-modal'

import './add-user-form.scss'

interface Props {
  centreId: string
  buttonText: string
  role: string
  usersInRole: number
  getUserByEmail: (email: string) => Promise<ManagedUser | null>
  createUserTask: AsyncTask<AxiosResponse<ManagedUser>>
}

export const AddUserForm: React.FC<Props> = ({
  centreId,
  buttonText,
  getUserByEmail,
  createUserTask,
  usersInRole,
  role,
}): JSX.Element => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [emailInvalid, setEmailInvalid] = useState(false)

  const [addUserEvent, setAddUserEvent] = useState<null | AddUserEvents>(null)
  const [getUserByEmailPending, setGetUserByEmailPending] = useState(false)
  const [addUserModalData, setAddUserModalData] = useState<any | null>(null)

  useEffect(() => {
    if (createUserTask.result) {
      setName('')
      setSurname('')
      setEmail('')
    }
  }, [createUserTask.result])

  const errorFriendlyMessage = useMemo(() => {
    if (createUserTask.error) {
      return (createUserTask.error as AxiosError).response?.data.message
    }
    return null
  }, [createUserTask.error])

  const inputs = useMemo(
    () => [
      {
        placeholder: 'First name',
        value: name,
        set: setName,
      },
      {
        placeholder: 'Last name',
        value: surname,
        set: setSurname,
      },
      {
        placeholder: 'Email address',
        value: email,
        set: (val: string) => {
          setEmail(val)
          setEmailInvalid(val.length !== 0 && !validateEmail(val))
        },
        invalid: emailInvalid,
      },
    ],
    [name, surname, email, emailInvalid, setName, setSurname, setEmail]
  )

  const pending =
    (createUserTask.started && createUserTask.pending) || getUserByEmailPending
  const enabled = !pending && (role !== ROLES.HOC || usersInRole === 0)

  const buttonEnabled =
    name.length > 0 &&
    surname.length > 0 &&
    email.length > 0 &&
    !emailInvalid &&
    enabled

  const addUser = useCallback(() => {
    ReactGA.event({
      category: 'User',
      action: `Added a ${role}`,
      label: `Centre : ${centreId}`,
    })

    createUserTask.start({
      data: {
        name,
        role,
        surname,
        email,
      },
    })
  }, [createUserTask, centreId, name, role, surname, email])

  const onAddUserClicked = useCallback(async () => {
    if (buttonEnabled && !pending) {
      setGetUserByEmailPending(true)
      const user = await getUserByEmail(email)
      const event =
        user === null ? null : calculateAddUserEvent(user, role, centreId)
      if (event === null) {
        addUser()
      } else {
        setAddUserModalData({
          name,
          surname,
          email,
          role,
          centreId,
          userRole: user?.role,
          userCentres: user?.centres,
        })
        setAddUserEvent(event)
      }
      setGetUserByEmailPending(false)
    }
  }, [
    buttonEnabled,
    pending,
    getUserByEmail,
    email,
    role,
    centreId,
    addUser,
    name,
    surname,
  ])

  return (
    <div className="add-user-form">
      <Row className="align-items-center mt-4">
        {inputs.map(({ placeholder, value, set, invalid }, idx) => (
          <Col
            className="mb-2"
            md={idx !== 2 ? 5 : 8}
            key={`user-form-col-${idx}`}
          >
            <input
              className={`px-3 font-weight-bold form-control ${
                invalid ? 'is-invalid' : ''
              }`}
              onChange={(e) => set(e.target.value)}
              value={value}
              placeholder={placeholder}
              disabled={!enabled}
              type="text"
              data-testid={`add-user-form-${snakeCase(placeholder)}`}
            />
          </Col>
        ))}
        <Col className="mb-2" md={6}>
          <Label
            className={`add-user-button px-3 py-2 mb-0 ${
              enabled && buttonEnabled ? 'enabled' : ''
            }`}
            onClick={onAddUserClicked}
            data-testid="add-user-form-add_user_button"
          >
            {pending ? (
              <Spinner size="sm" />
            ) : (
              <>
                <FontAwesomeIcon className="mr-3" icon={faPlus} />
                {buttonText}
              </>
            )}
          </Label>
        </Col>
      </Row>
      {createUserTask.error && (
        <Row>
          <Col md={{ size: 14, offset: 10 }}>
            <FormFeedback className="mt-2 px-3 text-left d-block">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
              {errorFriendlyMessage ||
                'Failed to create user, please try again'}
            </FormFeedback>
          </Col>
        </Row>
      )}
      <AddUserModal
        show={addUserEvent !== null && addUserModalData !== null}
        onClose={() => {
          setAddUserEvent(null)
          setAddUserModalData(null)
        }}
        onSubmit={addUser}
        addUserInProgress={pending}
        showError={!!createUserTask.error}
        userAddEvent={addUserEvent || 0}
        userData={addUserModalData}
      />
    </div>
  )
}
