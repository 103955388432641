import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap'

import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  val: string
  placeholder: string
  filterChanged: (val: string) => void
  onClick?: () => void
  onFilterClear?: () => void
  searchOnChange?: boolean
  disableSearch?: boolean
  dataTestid?: string
}

export const SearchBar: React.FC<Props> = ({
  val,
  placeholder,
  onClick,
  filterChanged,
  onFilterClear,
  searchOnChange = true,
  disableSearch = false,
  dataTestid,
}): JSX.Element => {
  return (
    <div className="search-bar">
      <InputGroup>
        <input
          className="px-3 form-control"
          onChange={(e) => filterChanged(e.target.value)}
          value={val}
          placeholder={placeholder}
          onKeyPress={
            onClick && !disableSearch
              ? (event) => {
                  if (
                    event.key === 'Enter' ||
                    (searchOnChange && event.charCode === 13)
                  ) {
                    event.preventDefault()
                    onClick()
                  }
                }
              : undefined
          }
          type="text"
          data-testid={`${dataTestid}-input`}
        />
        {val.length > 0 && (
          <span
            className="delete-icon"
            onClick={() =>
              onFilterClear ? onFilterClear() : filterChanged('')
            }
          >
            <FontAwesomeIcon className="text-secondary" icon={faTimes} />
          </span>
        )}
        <InputGroupAddon addonType="append">
          {onClick && (
            <Button
              onClick={onClick}
              color="primary"
              disabled={disableSearch}
              data-testid={`${dataTestid}-button`}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          )}
          {!onClick && (
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          )}
        </InputGroupAddon>
      </InputGroup>
    </div>
  )
}
